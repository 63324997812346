<template>
  <div>
    <div class="background-image" v-bind:style="{ backgroundImage: 'url(' + contentItemData[0]?.content[1][0]?.background_image + ')' }">
      <div class="overlay-image">
        <HeaderComponent></HeaderComponent>
        <!-- <div class="logo-section">
        <div class="left-logo-section">
          <img src="../assets/images/logo.png" alt="" />
        </div>
        <div class="right-logo-section">
            <router-link class="home-logo" to="/">
                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor"
                class="bi bi-house-door" viewBox="0 0 16 16">
                <path
                    d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z" />
                </svg><span>HOME</span>
            </router-link>
            <router-link class="tvshows-logo" to="/">
                  <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-play-btn"
                  viewBox="0 0 16 16">
                  <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                  <path
                      d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                  </svg>
                  <span>TV SHOWS</span>
            </router-link>
        </div>
      </div> -->
        <div class="main-content">
          <div class="title">
            {{ contentItemData[0].name }}
          </div>
          <div class="meta">
            <span class="iconvalue">
              {{ contentItemData[0].content[1][0].display_name }} |
            </span>
            <span class="iconvalue">
              <img width="21" height="21" src="../assets/images/durationicon.png" />{{
                contentItemData[0].content[1][0].duration }}
              |
            </span>
            <span class="iconvalue"> <img width="21" height="21" src="../assets/images/yearicon.png" />{{
              contentItemData[0].content[1][0].release_year
            }} |
            </span>
            <span class="iconvalue">
              <img width="21" height="21" src="../assets/images/ratingicon.png" /> {{ contentItemData[0].rating }}
            </span>
          </div>
          <b class="high-light">Overview</b>
          <div class="description">
            {{ contentItemData[0].description }}
            <br />
            <!-- {{ contentItemData[0].content[1][0].description }} -->
          </div>
          <div class="buttons">
            <router-link 
                v-if="contentItemData[0]?.id"
                :to="{
                    name: 'tvshowsdetail',
                    params: { id: contentItemData[0]?.id },
                }"
            >
              <button class="watch-now">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-play-fill"
                  viewBox="0 0 16 16">
                  <path
                    d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                </svg>
                Watch Now
              </button>
            </router-link>
            <router-link 
                v-if="contentItemData[0]?.id"
                :to="{
                    name: 'tvshowsdetail',
                    params: { id: contentItemData[0]?.id },
                }"
            >
              <button class="more-episodes">More Episodes</button>
            </router-link>
          </div>
          <!-- <b class="high-light">Cast and Crew</b><br /> -->
          <!-- {{ contentItemData[0].content[1][0].cast
          }} {{
        contentItemData[0].content[1][0].director
        ? ' | ' +
        contentItemData[0].content[1][0].director
        : ''
      }} {{
        contentItemData[0].content[1][0].production_company
        ? ' | ' +
        contentItemData[0].content[1][0].production_company
        : ''
      }} -->
          <!-- <div class="meta">
          Uche meets Marianna | Year : 2022 | Duration :22m 33s
        </div>
        <div class="description">
          An interview talk show with Mariana Orji discussing the media
          industry and influencers.
        </div> -->
          <!-- <div class="buttons">
          <div>
            <router-link 
                v-if="bannerCallApiResponse?.length"
                :to="{
                    name: 'tvshowsdetail',
                    params: { id: bannerCallApiResponse[0].video_id },
                }"
            >
            <button class="watch-now">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-play-fill"
                viewBox="0 0 16 16">
                <path
                  d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
              </svg>
              Show Episodes
            </button>
            </router-link>
          </div>
          <div>
          </div>
        </div> -->
          <!-- <div class="tvseriespagecontentrow seasons" >
            <router-link
                v-for="(item, key) in tvseriesApiResponse"
                :key="key"
                :to="{ name: 'tvshowsdetail', params: { id: item.id } }">
                    <img  :src="item.image" alt="">
            </router-link>
        </div> -->
          <div class="tvseriespagecontentrow seasons">
            <div v-for="(item, key) in tvseriesApiResponse" :key="key">
              <img :src="item.image" :class="item.id" alt="" @mouseover="selectedContentId = item.id; categoryCall();">
            </div>
          </div>
        </div>
      </div>
      <FooterComponent></FooterComponent>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import $ from "jquery";
import FooterComponent from "@/components/footer.vue";
import HeaderComponent from "@/components/header.vue";
export default {
  name: "tvshows",
  data() {
    return {
      selectedContentId: 8,
      contentItemData: null,
      tvSeriesBannersApiUrl:"https://api.kedutv.com/public/index.php/banners/sections/tvseries",
      bannerCallApiResponse: null,
      tvseriesApiUrl: "https://api.kedutv.com/public/index.php/category",
      ApiUrl: "https://api.kedutv.com/public/index.php/category/",
      tvseriesApiResponse: null,
      sliderInterval: null,
      showArrows: false,
      isloading: false,
    };
  },
  components: {
    FooterComponent,
    HeaderComponent,
  },
  async mounted() {
    await this.loadPageData();
  },
  methods: {

    loadPageData: function () {
      // Banners Call tv series
      var self = this;
      this.categoryCall();
      this.tvseriesBannersApi();
      // this.setBannerSlider();
      //tvseries call
      this.tvseriesContentApi();
      setTimeout(function () {
          self.enableshowsDragger();
      }, 2000);
    },
    categoryCall: function () {
      console.log(this.selectedContentId)
      var self = this;
      this.isloading = true;
      var settings = {
        "url": this.ApiUrl + this.selectedContentId,
        "method": "GET",
        "timeout": 15000,
        "processData": false,
        "mimeType": "multipart/form-data",
        "contentType": false
      };
      $.ajax(settings)
        .done(function (response) {
          self.contentItemData = [JSON.parse(response)];
          console.log(self.contentItemData);
          //self.intialRating();
          self.isloading = false;
        })
        .fail(function (xhr) {
          self.isloading = false;
          self.contentItemData = null;
          setTimeout(function () {
            self.categoryCall();
          }, 5000);
        });
    },
    enableshowsDragger: function () {
      const slider = document.querySelector(".tvseriespagecontentrow");
      let isDown = false;
      let startX;
      let scrollLeft;
      if (slider) {
        slider.addEventListener("mousedown", (e) => {
          isDown = true;
          slider.classList.add("active");
          startX = e.pageX - slider.offsetLeft;
          scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
          isDown = false;
          slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
          isDown = false;
          slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider.offsetLeft;
          const walk = (x - startX) * 3; //scroll-fast
          slider.scrollLeft = scrollLeft - walk;
          console.log(walk);
        });
      }
    },

    tvseriesBannersApi: function () {
      var self = this;
      this.isloading = true;
      var settings = {
        url: this.tvSeriesBannersApiUrl,
        method: "GET",
        timeout: 15000,
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
      };
      $.ajax(settings)
        .done(function (response) {
          self.bannerCallApiResponse = JSON.parse(response);
          console.log(self.bannerCallApiResponse)
          self.isloading = false;
        })
        .fail(function (xhr) {
          self.isloading = false;
          self.bannerCallApiResponse = null;
          setTimeout(function () {
            self.tvseriesBannersApi();
          }, 5000);
        });
    },
    tvseriesContentApi: function () {
      var self = this;
      this.isloading = true;
      var form = new FormData();
      form.append("limit", "20");
      var settings = {
        url: this.tvseriesApiUrl,
        method: "POST",
        timeout: 15000,
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        data: form,
      };
      $.ajax(settings)
        .done(function (response) {
          self.tvseriesApiResponse = JSON.parse(response);
          self.isloading = false;
        })
        .fail(function (xhr) {
          self.isloading = false;
          self.tvseriesApiResponse = null;
          setTimeout(function () {
            self.tvseriesContentApi();
          }, 5000);
        });
    },
    // setBannerSlider: function () {
    //   if (this.sliderInterval == null)
    //     this.sliderInterval = setInterval(this.nextSliderItem, 5000);
    // },
    // resetBannerSlider: function () {
    //   clearInterval(this.sliderInterval);
    //   this.sliderInterval = null;
    // },
    // nextSliderItem: function () {
    //   if (this.bannerCallApiResponse?.length) {
    //     var item = this.bannerCallApiResponse.shift();
    //     this.bannerCallApiResponse.push(item);
    //   }
    // },
    // perviousSliderItem: function () {
    //   if (this.bannerCallApiResponse != null) {
    //     var item = this.bannerCallApiResponse.pop();
    //     this.bannerCallApiResponse.unshift(item);
    //   }
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.main-content .meta .iconvalue {
  display: inline-flex;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
}

.main-content .meta {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.1rem;
  margin-top: 2rem;
  gap: 1rem;
}

.background-image {
  /* background-image: url("../assets/images/backgorundimage.avif"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100dvh;
}

.overlay-image {
  background-image: url("../assets/images/shade.png");
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  min-height: 100dvh;
  width: 100%;
  background-repeat: no-repeat;
}

.logo-section {
  font-family: sans-serif;
  padding: 2rem 2% 0rem 2%;
  display: flex;
  justify-content: space-between;
}

.left-logo-section img {
  height: auto;
  width: 7.5rem;
}

.home-logo span,
.tvshows-logo span {
  color: white;
  position: relative;
  top: 0.4rem;
  margin-left: .3rem;
  font-size: 1.5rem;
}

.home-logo svg,
.tvshows-logo svg {
  color: white;
  position: relative;
  font-size: 1.5rem;
}

.tvshows-logo svg,
.tvshows-logo span {
  color: indianred;
}

.right-logo-section {
  display: flex;
  gap: 3rem;
}

.main-content {
  color: white;
  font-family: sans-serif;
  margin: 0px 10% 0% 10%;
  padding-top: 8%;
  line-height: 2rem;
}

.main-content .title {
  font-family: "Pacifico", cursive;
  font-size: 2rem;
}

.main-content .meta {
  font-size: 1.1rem;
}

.main-content .description {
  font-size: 1.05rem;
  line-height: 1.5rem;
}

.main-content .watch-now {
  color: white;
  background: linear-gradient(180deg,
      rgba(162, 48, 100, 1) 0%,
      rgba(230, 81, 32, 1) 50%,
      rgb(65, 15, 85) 100%);
  font-size: 1.5rem;
  padding: 0.5rem 0.8rem;
  border-radius: 4rem;
  font-weight: 600;
  border: none;
}

.main-content .watch-now svg {
  position: relative;
}

.main-content .more-episodes {
  color: black;
  background: white;
  font-size: 0.9rem;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  font-weight: 600;
  border: none;
}

.main-content .buttons {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.seasons {
  display: flex;
  gap: 1rem;
  overflow-x: scroll;
  cursor: pointer;
  margin-top: 14%;
  padding-bottom: 4rem;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.seasons img {
  height: 9.375rem;
  width: auto;
  border-radius: 1rem;
}

.seasons::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 500px) {
  .home-logo {
    display: none;
  }

  .tvshows-logo span {
    font-size: 1rem;
  }

  .main-content .buttons {
    flex-direction: column;
  }

  .main-content .title {
    font-size: 1.75rem;
  }

  .seasons img {
    height: 8rem;
  }

  .home-logo span,
  .tvshows-logo span {
    bottom: 1rem;
  }
}
</style>
