var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"background-image",style:({ backgroundImage: 'url(' + _vm.contentItemData[0]?.content[1][0]?.background_image + ')' })},[_c('div',{staticClass:"overlay-image"},[_c('HeaderComponent'),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.contentItemData[0].name)+" ")]),_c('div',{staticClass:"meta"},[_c('span',{staticClass:"iconvalue"},[_vm._v(" "+_vm._s(_vm.contentItemData[0].content[1][0].display_name)+" | ")]),_c('span',{staticClass:"iconvalue"},[_c('img',{attrs:{"width":"21","height":"21","src":require("../assets/images/durationicon.png")}}),_vm._v(_vm._s(_vm.contentItemData[0].content[1][0].duration)+" | ")]),_c('span',{staticClass:"iconvalue"},[_c('img',{attrs:{"width":"21","height":"21","src":require("../assets/images/yearicon.png")}}),_vm._v(_vm._s(_vm.contentItemData[0].content[1][0].release_year)+" | ")]),_c('span',{staticClass:"iconvalue"},[_c('img',{attrs:{"width":"21","height":"21","src":require("../assets/images/ratingicon.png")}}),_vm._v(" "+_vm._s(_vm.contentItemData[0].rating)+" ")])]),_c('b',{staticClass:"high-light"},[_vm._v("Overview")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.contentItemData[0].description)+" "),_c('br')]),_c('div',{staticClass:"buttons"},[(_vm.contentItemData[0]?.id)?_c('router-link',{attrs:{"to":{
                  name: 'tvshowsdetail',
                  params: { id: _vm.contentItemData[0]?.id },
              }}},[_c('button',{staticClass:"watch-now"},[_c('svg',{staticClass:"bi bi-play-fill",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"}})]),_vm._v(" Watch Now ")])]):_vm._e(),(_vm.contentItemData[0]?.id)?_c('router-link',{attrs:{"to":{
                  name: 'tvshowsdetail',
                  params: { id: _vm.contentItemData[0]?.id },
              }}},[_c('button',{staticClass:"more-episodes"},[_vm._v("More Episodes")])]):_vm._e()],1),_c('div',{staticClass:"tvseriespagecontentrow seasons"},_vm._l((_vm.tvseriesApiResponse),function(item,key){return _c('div',{key:key},[_c('img',{class:item.id,attrs:{"src":item.image,"alt":""},on:{"mouseover":function($event){_vm.selectedContentId = item.id; _vm.categoryCall();}}})])}),0)])],1),_c('FooterComponent')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }